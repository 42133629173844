
import Swiper from 'swiper/bundle';
export default {
  init() {
    // JavaScript to be fired on all pages
    $('.bar-menu').click(function(e) {
      e.preventDefault();
      $('#main-menu').toggleClass('menu-active');
      $('.right-side .ubermenu-submenu').matchHeight();
      $('.right-side .ubermenu-target').matchHeight();
    });
    new Swiper('.gallery-image', {
      slidesPerView: 1,
      autoplay: true,
      loop: true,
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
